/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import * as styles from "./layout.module.scss"

const cx = classNames.bind(styles)

const Layout = ({ includeHeaderOffset = false, children }) => (
  <div className={cx({
    'headerOffset': includeHeaderOffset
  })}>
    <main>{children}</main>
    <footer></footer>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

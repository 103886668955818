import * as React from "react"
import classNames from "classnames/bind"

import Layout from "../components/atoms/layout/Layout"
import Seo from "../components/utils/seo"
import * as styles from "../components/atoms/layout/layout.module.scss"

const cx = classNames.bind(styles)

const NotFoundPage = () => (
  <Layout includeHeaderOffset>
    <Seo title="404: Not found" />
    <div className={cx('fullHeight')}>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
    </div>
  </Layout>
)

export default NotFoundPage
